import React from 'react';
import ShowcasePage from './showcase/showcase-page';
import SectionHeader from './showcase/section-header';
import { Box, Grid } from '@mui/material';
import { COLORS } from '../constants';
import { ImageContainer } from '../components/image-container';
import lizMartin1 from '../images/lizMartin1.svg';
import lizMartin2 from '../images/lizMartin2.svg';
import parkNavPoster from '../images/parkNavPoster.svg';
import LinkButton from './main/link-button';
import { Spacing } from '../components/spacing';
import parkNavStep3 from '../images/parknavStep3.png';
import parkNavStep41 from '../images/parknavStep4-1.png';
import parkNavStep42 from '../images/parknavStep4-2.png';
import parkNavStep43 from '../images/parknavStep4-3.png';
import parkNavStep44 from '../images/parknavStep4-4.png';
import parkNavStep5 from '../images/parknavStep5.png';

export default function Parknav() {
  return (
    <ShowcasePage>
      <Box component="h1" mb={4} sx={{ color: COLORS.showcase }}>
        ParkNav
      </Box>

      <Box component="p" mb={2}>
        Reimagining the way we interact with technology and information finding
        in parks
      </Box>
      <Spacing size="medium">
        <ImageContainer
          images={[parkNavPoster]}
          alts={['ParkNav poster']}
          clickable
        />
      </Spacing>
      <Box mb={5}>
        <LinkButton
          href="https://www.figma.com/proto/3TAQZoqxRcRxeVpmh4XEIw/Group-10-Interaction-Design-Work?page-id=123%3A138&node-id=828%3A24607&viewport=297%2C48%2C0.22&scaling=scale-down&starting-point-node-id=828%3A24607&show-proto-sidebar=1"
          color={COLORS.showcase}
          textColor="white"
        >
          View the prototype in figma here
        </LinkButton>
      </Box>

      <Grid container rowSpacing={5} columnSpacing={2}>
        <Grid item xs={12}>
          <SectionHeader>About</SectionHeader>
          <p>
            ParkNav is a project I worked on over 5 weeks with a team of 3 other
            HCI Design masters students at City, University of London. The idea
            behind the project was to design an app and a kiosk interface where
            park visitors (specifically Regent’s Park in London) could navigate
            the park more easily, contribute to the map in unique ways, quickly
            and efficiently check the sports pitches’ availability and book a
            pitch for use, connect with other people, find or host events, and
            join communities based on shared interests.
          </p>
        </Grid>

        <Grid item xs={12}>
          <iframe
            src="https://docs.google.com/presentation/d/e/2PACX-1vQ2KtRNFXvs34RMw14B8sYAo-b_cAyLqqJBVKkqnPlNdERzCsO-fOVyogR-YBoQ76eAioV0a_tK2xzE/embed?start=true&loop=true&delayms=15000"
            frameBorder="0"
            width="100%"
            height="488px"
            allowFullScreen
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
          ></iframe>
        </Grid>
      </Grid>

      <SectionHeader>My Role</SectionHeader>
      <p>
        I served as the primary product functionality decision-maker for our
        team, deciding (based on research, good intuition, and our users’ needs)
        what features we needed to include, down to the button. I also had a
        strong hand in much of the user research, wireframing and prototyping,
        and user evaluation design and execution.
      </p>

      <SectionHeader>My Process</SectionHeader>
      <Box sx={{ color: COLORS.showcase, fontWeight: 'bold' }}>
        <ol>
          <li>
            <Spacing size="medium">
              Conduct user observations and interviews on-site
            </Spacing>
          </li>
          <li>
            <Spacing size="small">
              Draw on research findings to create personas and user journeys,
              improving empathy to audience
            </Spacing>
            <Spacing size="medium">
              <ImageContainer
                images={[lizMartin1, lizMartin2]}
                alts={['Persona', 'User Journey']}
                clickable
              />
            </Spacing>
          </li>
          <li>
            <Spacing size="small">Brainstorm ideas as a team</Spacing>
            <Spacing size="medium">
              <ImageContainer
                images={[parkNavStep3]}
                alts={['Brainstorm ideas as a team']}
                clickable
                limitWidth={600}
              />
            </Spacing>
          </li>
          <li>
            <Spacing size="small">
              Iteratively design from low- to high-fidelity designs
            </Spacing>
            <Spacing size="medium">
              <ImageContainer
                images={[
                  parkNavStep41,
                  parkNavStep42,
                  parkNavStep43,
                  parkNavStep44,
                ]}
                alts={['Sketches', 'Lo-fi', 'Hi-fi 1', 'Hi-fi 2']}
                clickable
              />
            </Spacing>
          </li>
          <li>
            <Spacing size="small">
              Evaluate the screens with task-based usability testing, looking
              for potential issues
            </Spacing>
            <Spacing size="medium">
              <ImageContainer
                images={[parkNavStep5]}
                alts={['User evaluation tasks']}
                clickable
                limitWidth={600}
              />
            </Spacing>
          </li>
          <li>
            Edit the design based on the evaluation feedback and move forward
          </li>
        </ol>
      </Box>
    </ShowcasePage>
  );
}
